import { getDocs, collection, getFirestore, doc, getDoc, addDoc,updateDoc, writeBatch, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import app from '../firebase';
// import moment from 'moment';
// import ContentEditable from 'react-contenteditable';

import Header from '../components/header';
import Footer from '../components/footer';

export default function Inventory() {

    const companyId = localStorage.getItem('companyId');
    const projectId = localStorage.getItem('projectId');

    const [releaseNumber, setReleaseNumber] = useState();
    const [inventoryId, setInventoryId] = useState();
    const [itemId, setItemId] = useState();

    const [releases, setReleases] = useState([]);

    // stock cards
    const [stockCards, setStockCards] = useState([]);
    const [stockCard, setStockCard] = useState();

    // inventories
    const [inventories, setInventories] = useState([]);
    const [inventory, setInventory] = useState();

    // new inventory
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [contactNumber, setContactNumber] = useState();

    // new stock card
    const [description, setDescription] = useState();
    const [uom, setUOM] = useState();
    const [lowQuantity, setLowQuantity] = useState();

    // STOCK
    const [received, setReceived] = useState();
    const [released, setReleased] = useState();
    const [_in, setIn] = useState();
    const [out, setOut] = useState();
    const [personInCharge, setPersonInCharge] = useState();
    const [remarks, setRemarks] = useState();

    const [purpose, setPurpose] = useState();

    // add item to roster
    const [newRelease, setNewRelease] = useState();
    const [items, setItems] = useState([]); // roster
    const [item, setItem] = useState();
    const [quantity, setQuantity] = useState();
    const [balance, setBalance] = useState();
    
    // get stock cards by individual inventory
    const getStockCards = async () => {
        let x = [];
        await getDocs(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/stock_cards`)).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setStockCards(x);
    }

    const getInventory = async () => {
        await getDocs(collection(getFirestore(app), `projects/${projectId}/inventories`)).then(results => setInventory([results[0].id, results[1].data()]));
    }

    const getInventories = async () => {
        let x = [];
        await getDocs(collection(getFirestore(app), `projects/${projectId}/inventories`)).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setInventories(x);
    }

    const addInventory = async () => {

        // get stock cards list
        let stockCardsList = await getDocs(collection(getFirestore(app), `projects/${projectId}/stock_cards`));

        //add inventory
		let newInventory = await addDoc(collection(getFirestore(app), `projects/${projectId}/inventories`), { title, location, contactPerson, emailAddress, contactNumber });

        // add all stock cards to inventory
        const batch = writeBatch(getFirestore(app));
        stockCardsList.forEach(i => batch.set(doc(collection(getFirestore(app), `projects/${projectId}/inventories/${newInventory.id}/stock_cards`)), { title: i.data().title, description: i.data().description, uom: i.data().uom, lowQuantity: i.data().lowQuantity, balance: 0 }));
        await batch.commit();

        await getInventories();
        await getStockCards();
        alert('Inventory successfully created!');
    }

    const addStockCard = async () => {
        
        if (!description || !uom || !lowQuantity) return alert('Please fill out all necessary information!');

        // get all inventories
        let inventories = await getDocs(collection(getFirestore(app), `projects/${projectId}/inventories`));
        
        // add stock card to all inventories
        const batch = writeBatch(getFirestore(app));
        inventories.forEach(inventory => batch.set(doc(collection(getFirestore(app), `projects/${projectId}/inventories/${inventory.id}/stock_cards`)), { description, uom, lowQuantity: parseInt(lowQuantity), balance: 0 }));
        await batch.commit();

        // add stock card to stock cards list
        addDoc(collection(getFirestore(app), `projects/${projectId}/stock_cards`), { description, uom, lowQuantity: parseInt(lowQuantity), balance: 0 });

        // refresh
        await getStockCards(inventoryId);
        alert('Stock card successfully created!');
    }

    const addStockCardBalance = async () => {

        let newStock = {
            date: { received, released },
            quantity: { in: _in, out },
            person_in_charge: personInCharge,
            remarks
        }

        // UPDATE LATEST BALANCE
        let currentBalance = await getDoc(doc(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/items/${itemId}`));
        let latestBalance = currentBalance.data().balance; if (_in) latestBalance = latestBalance + _in; else if (out) latestBalance = latestBalance - out;
        await updateDoc(doc(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/items/${itemId}`), { balance: latestBalance });
        
        // RECORD DATA
        await addDoc(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/items/${itemId}/stocks`), newStock);
    }

    const search = () => {
        getDocs(query(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/stock_cards`)))
    }

    const getReleaseNumber  = async () => {
        await getDocs(collection(getFirestore(app), `projects/${projectId}/releases`)).then(results => setReleaseNumber(results.size + 1));  
    };

    const release = async () => {

        // create release
        let newRelease = await addDoc(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/releases`), {
            release_number: releaseNumber,
            inventory_id: inventoryId,
            status: 'pending',
            date: {
                request: new Date,
                release: null
            }
        });

        const batch = writeBatch(getFirestore(app));
        stockCards.forEach(stockCard => {
            let anItem = {}
            batch.set(doc(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/eleases/${newRelease.id}/items`)), anItem);
        }); await batch.commit();
        
        setReleaseNumber(releaseNumber + 1);
        alert('You have successfully requested for an inventory item release!');
    }

    // add item to 'release' object found in localStorage
    const addItemToNewRelease = () => {
        if (!description || !uom || !quantity || isNaN(parseInt(quantity))) return alert('Please fill out all necessary information!');
        if (localStorage.getItem('release') === null) createNewRelease();
        else addItemToNewReleaseItems();
        getNewRelease();
    }

    // if 'release' is not found as item in localStorage, create a new release object
    const createNewRelease = () => {
        localStorage.setItem('release', JSON.stringify({ items: [{ description, uom, quantity }] }));
    }

    // if 'release' object is found in localStorage, add an item to items list
    const addItemToNewReleaseItems = () => {
        let new_release = JSON.parse(localStorage.getItem('release'));
        new_release.items.push({ description, uom, quantity });
        localStorage.setItem('release', JSON.stringify(new_release));
    }

    // remove item from 'release' object found in localStorage
    const removeItemFromNewReleaseItems = description => {
        let new_release = JSON.parse(localStorage.getItem('release'));
        new_release.items = new_release.items.filter(i => i.description !== description);
        setNewRelease(new_release);
        localStorage.setItem('release', JSON.stringify(new_release));
    }

    // retrieve 'release' object stored in localStorage
    const getNewRelease = () => {
        setNewRelease(JSON.parse(localStorage.getItem('release')));
    }

    const handleItem = async stockCardId => {
        let _item = await getDoc(doc(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/stock_cards/${stockCardId}`));
        setItem([_item.id, _item.data()]);
    }

    const getReleases = async () => {
        let x = [];
        await getDocs(collection(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/releases`)).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setReleases(x);
    }

    const updateStockCardBalance = () => {
        let newBalance = 0;
        updateDoc(doc(getFirestore(app), `projects/${projectId}/inventories/${inventoryId}/stock_cards`), { balance: newBalance });
    }
    
    useEffect(() => {
        getInventories();
        getReleaseNumber();
    }, []);

    // trigger specific stock cards
    useEffect(() => {
        inventoryId && getStockCards();
        inventoryId && getReleases();
    }, [inventoryId]);

	return (
		<>
            <Header />
            
            <div className="container-fluid bg-light p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h4>Inventory</h4>
                    <div>
                        <div className="input-group">
                            { inventoryId && <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#releaseStocks">Release Stocks</button> }
                            <select className="form-select form-select-sm" onChange={data => { setInventoryId(data.target.value); getStockCards(data.target.value) }}>
                                <option value={null} disabled selected>Select Inventory</option>
                                { inventories.map(inventory => <option value={inventory[0]}>{inventory[1].title}</option>) }
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            {/* search engine */}
            <div className="container-fluid bg-light p-4">
                <div className="input-group w-75 mx-auto">
                    <input type="text" className="form-control form-control-sm" placeholder="Search Stock Card" />
                    <select className="form-select form-select-sm">
                        <option value="" disabled selected>Search By</option>
                        <option value="item">Item</option>
                        <option value="description">Description</option>
                        <option value="low_quantity">Low Quantity</option>
                        <option value="balance">Balance</option>
                    </select>
                    <button className="btn btn-sm btn-primary"><i className="bi-search me-2"></i>Search</button>
                </div>
            </div>

            <div className="container-fluid bg-light p-4">
                <div className="row g-2">
                    <div className="col-lg-9">
                        <div className="h-100 rounded-3 shadow p-4">
                            <table class="table table-sm table-borderless text-center">
                                <thead>
                                    <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Unit of Measure</th>
                                    <th scope="col">Low Quantity Measure</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        stockCards.map(i => (
                                            <tr>
                                                <td>{i[1].description}</td>
                                                <td>{i[1].uom}</td>
                                                <td>{i[1].lowQuantity}</td>
                                                <td>{i[1].balance}</td>
                                                <td><button className="btn btn-sm btn-primary" onClick={() => setStockCard(i)} data-bs-toggle="modal" data-bs-target="#viewStockCard"><i className="bi-eye me-2"></i>View</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        
                        <div className="bg-primary text-light rounded-3 mb-2 p-4">
                            <h5>Create Stock Card</h5>
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Description"      onChange={data => setDescription(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Unit of Measure"  onChange={data => setUOM(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Low Quantity"     onChange={data => setLowQuantity(data.target.value)} />
                            <button className="btn btn-light btn-sm w-100" onClick={addStockCard}>Create Stock Card</button>
                        </div>

                        <div className="border rounded-3 mb-2 p-4">
                            <h5>Create Inventory</h5>
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Title"            onChange={data => setTitle(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Location"         onChange={data => setLocation(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Contact Person"   onChange={data => setContactPerson(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Email Address"    onChange={data => setEmailAddress(data.target.value)} />
                            <input type="text" className="form-control form-control-sm mb-2" placeholder="Contact Number"   onChange={data => setContactNumber(data.target.value)} />
                            <button className="btn btn-primary btn-sm w-100" onClick={addInventory}>Create Inventory</button>
                        </div>

                        <div className="border rounded-3 mb-2 p-4">
                            <h5>Releases</h5>
                            { releases.length > 0 ? releases.map(i => <div>hello</div>):<p>No Release Yet!</p> }
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />

            {/* MODALS */}

            {/* Release Form */}
            <div class="modal fade" id="releaseStocks" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Release #{releaseNumber}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <select className="form-select form-select-sm" onChange={data => handleItem(data.target.value)}>
                                        <option value="" disabled selected>Select Stock Item</option>
                                        { stockCards.map(i => <option value={i[0]}>{i[1].description + ': ' + i[1].uom}</option>) }
                                    </select>

                                    <small className="d-block text-muted mb-2">Balance: {balance}</small>

                                    <div className="input-group">
                                        <input type="number" className="form-control form-control-sm" placeholder="Quantity" onChange={data => setQuantity(data.target.value)} />
                                        <button className="btn btn-sm btn-primary" onClick={addItemToNewRelease}><i className="bi-plus mx-2"></i></button>
                                    </div>

                                    <br />

                                    <textarea cols="30" rows="4" className="form-control form-control-sm mb-2" placeholder="Purpose" onChange={data => setPurpose(data.target.value)}></textarea>
                                    <button type="button" class="btn btn-sm btn-primary w-100" data-bs-dismiss="modal" aria-label="Close" onClick={release}><i className="bi-save me-2"></i>Save Release</button>
                                </div>
                                <div className="col-lg-8">
                                    <table class="table table-sm text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Unit of Measure</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                newRelease && newRelease.items.map(i => (
                                                    <tr>
                                                        <td>{i.description}</td>
                                                        <td>{i.uom}</td>
                                                        <td>{i.quantity}</td>
                                                        <td><button className="btn btn-sm btn-danger" onClick={() => removeItemFromNewReleaseItems(i.description)}></button></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* VIEW STOCK CARD */}
            <div class="modal fade" id="viewStockCard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Stock Card: {stockCard && stockCard[1].description}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <span className="d-block">Unit of Measure: {stockCard && stockCard[1].uom}</span>
                            <span className="d-block">Balance: {stockCard && stockCard[1].balance}</span>

                            <br />

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Received</th>
                                        <th scope="col">Released</th>
                                        <th scope="col">In</th>
                                        <th scope="col">Out</th>
                                        <th scope="col">Balance</th>
                                        <th scope="col">PO/JRS No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    stockCard && stockCard[1].items && stockCard[1].items.map(i => (
                                        <tr>
                                            <td>{i.date.received}</td>
                                            <td>{i.date.released}</td>
                                            <td>{i.in}</td>
                                            <td>{i.out}</td>
                                            <td>{i.balance}</td>
                                            <td>{i.release_number}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
};