import { getDocs, collection, getFirestore, doc, getDoc, updateDoc, addDoc, writeBatch, query, limit, orderBy } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import app from '../firebase';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import ContentEditable from 'react-contenteditable';
import zedc from '../zedc.png';

export default function OrderPrint() {

    const { order_id } = useParams();

    var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' });

    const [companyId, setCompanyId]     = useState(localStorage.getItem('companyId'));
	const [projectId, setProjectId]     = useState(localStorage.getItem('projectId'));

    const [order, setOrder] = useState();
    const [project, setProject] = useState();

    const getOrder = () => {
        getDoc(doc(getFirestore(app), `projects/${projectId}/orders/${order_id}`))
            .then(result => setOrder([result.id, result.data()]))
    }

    const getProject = () => {
        getDoc(doc(getFirestore(app), 'projects', projectId)).then(result => setProject([result.id, result.data()]));
    }

    const updateEntity = (entity, value) => {
        if (entity == 'preparedBy')         updateDoc(doc(getFirestore(app), `projects/${projectId}/orders/${order_id}`), { preparedBy: value });
        else if (entity == 'checkedBy')     updateDoc(doc(getFirestore(app), `projects/${projectId}/orders/${order_id}`), { checkedBy: value });
        else if (entity == 'approvedBy')    updateDoc(doc(getFirestore(app), `projects/${projectId}/orders/${order_id}`), { approvedBy: value });
    }

    useEffect(() => {
        getOrder();
        getProject();
    }, []);

	return (
		<>
            <div className="container-fluid">
                <img src={zedc} className="img-fluid d-block mx-auto" style={{ width: 800 }} />
                {/* <h1 className="text-center text-success">ZETH EDWARD DEVELOPMENT CORPORATION (OPC.)</h1>
                <small className="d-block text-center">0337 J.P. Rizal St., Mahabang Parang, Sta. Maria, Bulacan</small>
                <small className="d-block text-center">09153426297</small> */}

                <hr />
                
                <h6 className="text-center">PURCHASE ORDER FORM</h6>

                {/* order header details */}
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <small className="d-block">To: { order && order[1].to }</small>
                            <small className="d-block">Address: { order && order[1].address }</small>
                            <small className="d-block">Attention 1: { order && order[1].attentions[0] }</small>
                            <small className="d-block">Attention 2: { order && order[1].attentions[1] }</small>
                            <small className="d-block">Tel. #: { order && order[1].phone }</small>
                        </div>

                        <div className="col-6">
                            <small className="d-block">TIN: { order && order[1].tin }</small>
                            <small className="d-block">PO No.: { order && order[1].number }</small>
                            <small className="d-block">PO Date: { order && moment(order[1].date.order.toDate()).format('LL') }</small>
                            <small className="d-block">PRF No.: { order && order[1].request.number }</small>
                        </div>
                    </div>
                </div>

                <br />

                <table class="table table-sm text-center border">
                    <thead>
                        <tr>
                        <th scope="col"><small>No.</small></th>
                        <th scope="col"><small>Description</small></th>
                        <th scope="col"><small>Unit of Measure</small></th>
                        <th scope="col"><small>Quantity</small></th>
                        <th scope="col"><small>Unit Price</small></th>
                        <th scope="col"><small>Amount</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order && order[1].items.map((i, index) => (
                                <tr>
                                    <td className="fw-bold"><small>{index + 1}</small></td>
                                    <td><small>{i.description}</small></td>
                                    <td><small>{i.uom}</small></td>
                                    <td><small>{i.quantity}</small></td>
                                    <td><small>{formatter.format(i.price)}</small></td>
                                    <td><small>{formatter.format(i.amount)}</small></td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td className="align-middle" colspan="3"><small>Note: Always attach copy of PO on every delivery.</small></td>
                            <td colspan="2">
                                <small className="d-block fw-bold">Amount w/o Tax</small>
                                <small className="d-block fw-bold">VAT</small>
                                <small className="d-block fw-bold">Total Amount</small>
                            </td>
                            
                            <td>
                                <small className="d-block fw-bold">{ order && formatter.format(order[1].total.wo_tax) }</small>
                                <small className="d-block fw-bold">{ order && formatter.format(order[1].total.tax) }</small>
                                <small className="d-block fw-bold">{ order && formatter.format(order[1].total.amount) }</small>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <table class="table table-sm border">
                    <tbody>
                        <tr>
                            <td><small>Project Name: { project && project[1].title }</small></td>
                            <td><small>Delivery Date: { order && moment(order[1].date.delivery.toDate()).format('LL') }</small></td>
                        </tr>

                        <tr>
                            <td><small>Delivery To: { order && order[1].deliver_to }</small></td>
                            <td><small>Terms of Payment: { order && order[1].payment_terms }</small></td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <div className="row px-2">
                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Prepared by:</small>
                        <br />
                        <ContentEditable
                            html={ order && order[1].preparedBy ? order[1].preparedBy:'' }
                            onChange={data => updateEntity('preparedBy', data.target.value)}
                            tagName='div'
                            className="text-center fw-bold"
                            style={{ fontSize: 13 }}
                        />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>

                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Checked/Verified by:</small>
                        <br />
                        <ContentEditable
                            html={ order && order[1].checkedBy ? order[1].checkedBy:'' }
                            onChange={data => updateEntity('checkedBy', data.target.value)}
                            tagName='div'
                            className="text-center fw-bold"
                            style={{ fontSize: 13 }}
                        />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>

                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Approved by:</small>
                        <br />
                        <ContentEditable
                            html={ order && order[1].approvedBy ? order[1].approvedBy:'' }
                            onChange={data => updateEntity('approvedBy', data.target.value)}
                            tagName='div'
                            className="text-center fw-bold"
                            style={{ fontSize: 13 }}
                        />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>
                </div>

                <div className="row px-2">
                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 12 }}>Date:</small>
                        <br />
                    </div>

                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 12 }}>Date:</small>
                    </div>

                    <div className="col-md-4 border">
                        <small className="d-block" style={{ fontSize: 12 }}>Date:</small>
                    </div>
                </div>

                <br />

                <div className="row px-2">
                    <div className="col-md-8 border">
                        <p style={{ fontSize: 13 }}>We acknowledge receipt of the P.O. and agree to comply with all the terms, conditions set forth herein. Purchase Order is considered CONFIRMED once items and services has been served.</p>
                    </div>

                    <div className="col-md-4 border">
                        <span style={{ fontSize: 13 }}>Supplier's Signature and Date</span>
                    </div>
                </div>
            </div>
        </>
	);
};