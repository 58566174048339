import { useNavigate } from "react-router";

export default function Header() {
    const navigate   = useNavigate();
    const companyId = localStorage.getItem('companyId');
    const projectId = localStorage.getItem('projectId');
    

    return (
        <div className="container-fluid bg-light d-flex align-items-center justify-content-between px-4 py-2">
            <div>
                <h1>ZEDC Procurement</h1>
            </div>

            <div className=""></div>
            <div cla></div>
            <div>
                <select className="form-select form-select-sm" onChange={data => navigate(data.target.value)}>
                    <option value="" disabled selected>Select Module</option>
                    <option value="/dashboard">Dashboard</option>
                    <option value="/task">Task</option>
                    <option value="/request">Request</option>
                    <option value="/order">Order</option>
                    <option value="/inventory">Inventory</option>
                    <option value="/accounting">Accounting</option>
                    {/* <option value="/analytics">Analytics</option> */}
                    {/* <option value="/settings">Settings</option> */}
                </select>
            </div>
        </div>
    );
}