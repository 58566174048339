import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router";
import app from '../firebase';
import { useState } from "react";

export default function Register() {
	
	const navigate = useNavigate();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	const register = async () => {
		await createUserWithEmailAndPassword(getAuth(app), email, password);
		navigate('/login');
	}

	return(
		<div className="container-fluid bg-primary d-flex min-vh-100 align-items-center justify-content-center p-4">
            <div className="p-4 bg-light shadow rounded-3">
                <h1 className="text-dark"><i className="bi bi-shield-lock text-primary me-2"></i>Register</h1>

                <br />

                <input type="password" className="form-control mb-2" placeholder="Password" onChange={data => setPassword(data.target.value)} />
                
                <button className="btn btn-primary mb-2 w-100" onClick={register}>Register</button>
            </div>
		</div>
	)
};