/*
    NOTE: THIS IS THE FIRST COMPONENT THAT WILL BE DISPLAYED AFTER /DASHBOARD GET REQUEST
*/
import { useState, useContext, useEffect } from 'react';
import { Line } 		        from 'react-chartjs-2';
import app                      from '../firebase';
import { getFirestore, doc, collection, getDocs, addDoc, writeBatch, runTransaction } from 'firebase/firestore';

import Header from '../components/header';
import Footer from '../components/footer';

export default function CashReceiptsJournal() {

    const companyId = localStorage.getItem('companyId');
    const projectId = localStorage.getItem('projectId');

    const [date, setDate]                               = useState();
    const [accountCredited, setAccountCredited]         = useState();
    const [postReference, setPostReference]             = useState();
    const [explanation, setExplanation]                 = useState();
    const [cash, setCash]                               = useState();
    const [salesDiscount, setSalesDiscount]             = useState();
    const [accountsReceivable, setAccountsReceivable]   = useState();
    const [sales, setSales]                             = useState();
    const [otherAccounts, setOtherAccounts]             = useState();

    const [items, setItems] = useState([]);

    const [totalCash, setTotalCash]                             = useState();
    const [totalSalesDiscount, setTotalSalesDiscount]           = useState();
    const [totalAccountsReceivable, setTotalAccountsReceivable] = useState();
    const [totalSales, setTotalSales]                           = useState();
    const [totalOtherAccounts, setTotalOtherAccounts]           = useState();


    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP'});

    const getTotalCash = async () => {
        let x = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x = x + result.data().cash));
        setTotalCash(x);

    }

    const getTotalSalesDiscount = async () => {
        let x = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x = x + result.data().salesDiscount));
        setTotalSalesDiscount(x);

    }

    const getTotalAccountsReceivable = async () => {
        let x = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x = x + result.data().accountsReceivable));
        setAccountsReceivable(x);

    }

    const getTotalSales = async () => {
        let x = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x = x + result.data().totalSales));
        setTotalSales(x);

    }

    const getTotalOtherAccounts = async () => {
        let x = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x = x + result.data().otherAccounts));
        setOtherAccounts(x);
    }

    useEffect(async () => {
        await get();
        await getTotalCash();
        await getTotalSalesDiscount();
        await getTotalAccountsReceivable();
        await getTotalSales();
        await getTotalOtherAccounts();
    }, []);

    const add = async () => {
        await addDoc(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`), { date: new Date(date), accountCredited, postReference, explanation, cash: parseFloat(cash), salesDiscount: parseFloat(salesDiscount), accountsReceivable: parseFloat(accountsReceivable), sales: parseFloat(sales), otherAccounts: parseFloat(otherAccounts) });
        await get();
    }

    const get = async () => {
        let x = [];
        await getDocs(collection(getFirestore(app), `projects/${projectId}/cashReceiptJournal`)).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setItems(x);
    }

    return (
        <>
            <Header />

            {/* Cash Receipts Journal */}
            <div className="container-fluid bg-light p-4">
                <h1>Cash Receipts Journal</h1>
                <div className="row">
                    <div className="col-md-3">
                        <div className="bg-primary bg-gradient text-light rounded-3 mb-3 p-3">
                            <div className="mb-2">
                                <small className="d-block">Total Cash</small>
                                <span className="d-block fw-bold">{formatter.format(totalCash)}</span>
                            </div>

                            <div className="mb-2">
                                <small className="d-block">Total Sales Discount</small>
                                <span className="d-block fw-bold">{formatter.format(totalSalesDiscount)}</span>
                            </div>

                            <div className="mb-2">
                                <small className="d-block">Total Accounts Receivable</small>
                                <span className="d-block fw-bold">{formatter.format(totalAccountsReceivable)}</span>
                            </div>

                            <div className="mb-2">
                                <small className="d-block">Total Sales</small>
                                <span className="d-block fw-bold">{formatter.format(totalSales)}</span>
                            </div>

                            <div className="mb-2">
                                <small className="d-block">Total Other Accounts</small>
                                <span className="d-block fw-bold">{formatter.format(totalOtherAccounts)}</span>
                            </div>
                            
                            <button className="btn btn-sm btn-light w-100 mt-2"><i class="bi bi-printer-fill me-2"></i>Print</button>
                        </div>

                        <input type="date" className="form-control mb-2" placeholder="Date"                onChange={data => setDate(data.target.value)} />
                        <input type="text" className="form-control mb-2" placeholder="Account Credited"    onChange={data => setAccountCredited(data.target.value)} />
                        <input type="text" className="form-control mb-2" placeholder="Post Reference"      onChange={data => setPostReference(data.target.value)} />
                        <input type="text" className="form-control mb-2" placeholder="Explanation"         onChange={data => setExplanation(data.target.value)} />
                    
                        <input type="number" className="form-control mb-2" placeholder="Cash"                onChange={data => setCash(data.target.value)} />
                        <input type="number" className="form-control mb-2" placeholder="Sales Discount"      onChange={data => setSalesDiscount(data.target.value)} />
                        <input type="number" className="form-control mb-2" placeholder="Accounts Receivable" onChange={data => setAccountsReceivable(data.target.value)} />
                        <input type="number" className="form-control mb-2" placeholder="Sales"               onChange={data => setSales(data.target.value)} />
                        <input type="number" className="form-control mb-2" placeholder="Other Accounts"      onChange={data => setOtherAccounts(data.target.value)} />
                        <button className="btn btn-primary w-100" onClick={add}>Add Record</button>
                    </div>

                    <div className="col-md-9">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Account Credited</th>
                                    <th scope="col">Reference</th>
                                    <th scope="col">Explanation</th>
                                    <th scope="col">Cash</th>
                                    <th scope="col">Sales Discount</th>
                                    <th scope="col">Accounts Receivable</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col">Other Accounts</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map(item => (
                                        <tr>
                                            <td>{item[1].date}</td>
                                            <td>{item[1].accountCredited}</td>
                                            <td>{item[1].postReference}</td>
                                            <td>{item[1].explanation}</td>
                                            <td>{formatter.format(item[1].cash)}</td>
                                            <td>{formatter.format(item[1].salesDiscount)}</td>
                                            <td>{formatter.format(item[1].accountsReceivable)}</td>
                                            <td>{formatter.format(item[1].sales)}</td>
                                            <td>{formatter.format(item[1].otherAccounts)}</td>
                                        </tr>
                                    ))
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>

                <br />
                
                {/* <span className="d-block fs-5">Total Cash Dr: {totalCashDr}</span>
                <span className="d-block fs-5">Total Sales Discount Dr: {totalSalesDiscountDr}</span>
                <span className="d-block fs-5">Total Accounts REceivable Cr: {totalAccountReceivableCr}</span>
                <span className="d-block fs-5">Total sales Cr: {totalSalesCr}</span>
                <span className="d-block fs-5">total other accounts Cr: {totalOtherAccountsCr}</span> */}
            </div>

            <Footer />
        </>
    )
}