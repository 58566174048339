import { getDoc, getDocs, collection, getFirestore, addDoc, updateDoc, doc, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import app from '../firebase';

import Header from '../components/header';
import Footer from '../components/footer';

import moment from 'moment';

export default function Builder() {

    const companyId = localStorage.getItem('companyId');
    const projectId = localStorage.getItem('projectId');


    const [tasks, setTasks] = useState([]);
    const [percentageDone, setPercentageDone] = useState();
    
    // VARIABLES FOR TASK
    const [description, setDescription] = useState();
    const [start, setStart]             = useState();
    const [end, setEnd]                 = useState();
    const [budget, setBudget]           = useState();
    const [notes, setNotes]             = useState('');

    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP'});

    useEffect(async() => {
        await getPercentageDone();
        await getTasks();
    }, []);

    const getTasks = async () => {
        let x = [];
        await getDocs(query(collection(getFirestore(app), `projects/${projectId}/tasks`), where('done', '==', false))).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setTasks(x);
    }

    const addTask = async () => {
        if (description || start || end || budget) {
            await addDoc(collection(getFirestore(app), `projects/${projectId}/tasks`), { description, start: new Date(start), end: new Date(end), budget, done: false, notes });
            
            addBudget(budget);
            getTasks();
            getPercentageDone();
        }
    }

    const addBudget = async () => {
        await getDoc(doc(getFirestore(app), `projects/${projectId}`))
            .then(result => {
                let current = result.data().budget;
                updateDoc(doc(getFirestore(app), `projects/${projectId}`), { budget: budget + current });
            })
    }

    const getPercentageDone = async () => {
        let total = 0;
        let done = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/tasks`))
            .then(results => {
                total = results.size;
                results.forEach(result => { if (result.data().done) done = done + 1 });
            }); setPercentageDone(parseInt((done/total) * 100));
    }

    const update = (taskId, label, value) => {
        if      (label == 'description') updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { description: value });
        
        else if (label == 'start') {
            updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { start: new Date(value) });
        }
        else if (label == 'end') updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { end: new Date(value) });
        
        else if (label == 'budget') {
            updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { budget: parseFloat(value) });
        }
        else if (label == 'done') updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { done: value });
        else if (label == 'notes') updateDoc(doc(getFirestore(app), `projects/${projectId}/tasks/${taskId}`), { notes: value });
        
        getTasks();
        getPercentageDone();
    }

    const showDone = async () => {
        let x = [];
        await getDocs(query(collection(getFirestore(app), `projects/${projectId}/tasks`), where('done', '==', true))).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setTasks(x);
    }

    return (
        <>
            <Header />

            <div className="container-fluid bg-light p-4">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="row g-2">
                            <div className="col-6">
                                <div className="h-100 bg-primary bg-gradient text-light rounded-3 p-4">
                                    <span className="d-block">The project is</span>
                                    <h1>{percentageDone}%</h1>
                                    <span className="d-block">Finished.</span>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="h-100 bg-success bg-gradient text-light rounded-3 p-4">
                                    <span className="d-block">Total Budget</span>
                                    <h1>PHP 200,000,000.00</h1>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="h-100 bg-secondary bg-gradient text-light rounded-3 p-4">
                                    <span className="d-block">Used Budget</span>
                                    <h1>PHP 100,000,000.00</h1>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="h-100 bg-danger bg-gradient text-light rounded-3 p-4">
                                    <span className="d-block">Remaining Budget</span>
                                    <h1>PHP 100,000,000.00</h1>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-12 col-md-6">
                        <h4>Add New Task</h4>
                        <input type="text" className="form-control mb-2" placeholder="Description" onChange={data => setDescription(data.target.value)} />
                        <input type="date" className="form-control mb-2" placeholder="Start"       onChange={data => setStart(data.target.value)} />
                        <input type="date" className="form-control mb-2" placeholder="Finish"      onChange={data => setEnd(data.target.value)} />
                        <input type="text" className="form-control mb-2" placeholder="Budget"      onChange={data => setBudget(parseFloat(data.target.value))} />
                        <input type="text" className="form-control mb-2" placeholder="Notes"       onChange={data => setNotes(data.target.value)} />
                        <button className="btn btn-primary w-100" onClick={addTask}>Add Task</button>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2>List of Tasks</h2>
                    <button className="btn btn-primary" onClick={showDone}>Show Done</button>
                </div>
                    
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">Start</th>
                            <th scope="col">End</th>
                            <th scope="col">Budget</th>
                            <th scope="col">Status</th>
                            <th scope="col">Notes</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tasks.map(task => (
                                <tr>
                                    <td>{task[1].description}</td>
                                    <td>{moment(task[1].start.toDate()).format('LL')}</td>
                                    <td>{moment(task[1].end.toDate()).format('LL')}</td>
                                    <td>{formatter.format(task[1].budget)}</td>
                                    <td>pending</td>
                                    <td>{task[1].notes}</td>
                                    <td>
                                        { task[1].done == false && <button className="btn btn-sm btn-primary me-1" onClick={() => update(task[0], 'done', true)}><i className="bi-check me-2"></i>Mark As Done</button> }
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#editTask"><i className="bi-pen me-1"></i>Edit</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

           <Footer />

           {/* MODALS */}

           <div class="modal fade" id="editTask" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <input type="text" className="form-control mb-2" placeholder="Description" />

                            <div className="input-group mb-2">
                                <input type="date" className="form-control mb-2" />
                                <input type="date" className="form-control mb-2" />
                            </div>

                            <input type="text" className="form-control mb-2" placeholder="Budget" />
                            <input type="text" className="form-control mb-2" placeholder="Notes" />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary w-100" data-bs-dismiss="modal" aria-label="Close">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
	);
};