/*
    THIS PAGE IS PROTECTED WITH AUTH CONTEXT
*/

import app from '../firebase';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { useState, useEffect, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import moment from 'moment';

export default function Projects() {

    var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' });

    const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));
    const [userId, setUserId]       = useState(localStorage.getItem('userId'));

    const navigate = useNavigate();

    const [projects, setProjects]           = useState([]);
    const [title, setTitle]                 = useState();
    const [abbreviation, setAbbreviation]   = useState();
    const [description, setDescription]     = useState();
    const [location, setLocation]           = useState();
    const [budget, setBudget]               = useState();
    const [start, setStart]                 = useState();
    const [end, setEnd]                     = useState();

    useEffect(async () => {
        await getProjects();
        localStorage.removeItem('projectId');
    }, []);

    const getProjects   = () => getDocs(collection(getFirestore(app), `projects`)).then(results => { let x = []; results.forEach(result => x.push([result.id, result.data()])); setProjects(x); });
    const createProject = async () => {
        let newProject = { title, abbreviation, description, location, budget: parseFloat(budget), start: new Date(start), end: new Date(end) }
        await addDoc(collection(getFirestore(app), `projects`), newProject).then(result => localStorage.setItem('projectId', result.id))

        // REDIRECT TO HOME
        navigate('/dashboard')
    };
    
    const Projects = () => {
        return(
            <>
                {/* <div className="container-fluid p-4">
                    <img src="" alt="" />
                    <input type="text" className="form-control mb-2" placeholder="Business Name" />
                    <input type="text" className="form-control mb-2" placeholder="Business Address" />
                    <input type="text" className="form-control mb-2" placeholder="Business Contact" />
                </div> */}

                <div className="row g-2">
                    {
                        projects.map(project => (
                            <div className="col-12 col-lg-6">
                                <div className="bg-primary bg-gradient text-light rounded-2 shadow h-100 p-4" role="button" onClick={() => { localStorage.setItem('projectId', project[0]); navigate('/dashboard')}}>
                                    <h4>{ project[1]['title'] }</h4>
                                    <h6>{ project[1]['location'] }</h6>

                                    <small>{ project[1]['description'] }</small>

                                    <br /><br />

                                    From <small>{ moment(project[1]['start'].toDate()).format('LL') }</small>&nbsp;
                                    To <small>{ moment(project[1]['end'].toDate()).format('LL') }</small>

                                    <br /><br />
                                    <small className="fw-bold">{ formatter.format(project[1]['budget']) }</small>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </>
        );
    }

    const NoProject = () => {
        return(
            <div className="text-center">
                <br />
                <br />
                <br />
                <i className="bi bi-box display-1"></i>
                <h4>You have no project yet!</h4>
            </div>
        );
    }

    return localStorage.getItem('userId') && localStorage.getItem('userRole') && localStorage.getItem('companyId')  ? (
        <div className="container-fluid bg-light min-vh-100 p-4">
            
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createProject"><i className="bi bi-plus me-2"></i>Create Project</button>

            <br /><br />

            { projects.length > 0 ? <Projects />:<NoProject /> }

            {/* MODAL */}
            <div class="modal fade" id="createProject" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Create A Project</h5>
                    </div>
                    <div class="modal-body">
                        <div className="mb-2">
                            <label className="form-label fw-bold">Project Title</label>
                            <div className="mb-2 input-group">
                                <input type="text" className="form-control w-75" onChange={data => setTitle(data.target.value)} />
                                <input type="text" className="form-control w-25" placeholder="Abbrev" onChange={data => setAbbreviation(data.target.value)} />
                            </div>
                        </div>

                        <div className="mb-2">
                            <label className="form-label fw-bold">Project Description</label>
                            <input type="text" className="form-control" onChange={data => setDescription(data.target.value)} />
                        </div>

                        <div className="mb-2">
                            <label className="form-label fw-bold">Project Location</label>
                            <input type="text" className="form-control" onChange={data => setLocation(data.target.value)} />
                        </div>

                        <div className="mb-2">
                            <label className="form-label fw-bold">Project Budget</label>
                            <input type="text" className="form-control" onChange={data => setBudget(data.target.value)} />
                        </div>

                        <div className="mb-2">
                            <label className="form-label fw-bold">Project Duration</label>
                            <div className="mb-2 input-group">
                                <input type="date" className="form-control" onChange={data => setStart(data.target.value)} />
                                <input type="date" className="form-control" onChange={data => setEnd(data.target.value)} />
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={createProject}>Create Project</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
            ) : (<Navigate to="/login" />);
}