import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, getDoc, getDocs, doc, collection, runTransaction } from 'firebase/firestore';
import app from '../firebase';

import bisligFront from '../bislig-front.png';
import bisligRear from '../bislig-rear.png';
import bisligLeft from '../bislig-left.png';
import bisligRight from '../bislig-right.png';

import inspect1 from '../inspect1.jpg';
import inspect2 from '../inspect2.jpg';
import inspect3 from '../inspect3.jpg';
import inspect4 from '../inspect4.jpg';

import bislig from '../bislig-project.mp4';
import bisligShow from '../bislig.mp4';

import office1 from '../office1.jpg';
import office2 from '../office2.jpg';
import office3 from '../office3.jpg';
import office4 from '../office4.jpg';
import office5 from '../office5.jpg';
import office6 from '../office6.jpg';
import office7 from '../office7.jpg';
import office8 from '../office8.jpg';

export default function Login() {
    
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();

    const login = async () => {
        await signInWithEmailAndPassword(getAuth(app), email, password)
            .then(async result => {
                localStorage.setItem('userId', result.user.uid);
                await getUserProfile(result.user.uid);

                // enter the main
                navigate('/projects');
            }).catch(e => alert(e));
    };

    const getUserProfile = async userId => {
            await getDoc(doc(getFirestore(app), `users/${userId}`))
                .then(result => {
                    localStorage.setItem('userRole', result.data().role);
                    localStorage.setItem('companyId', result.data().company);
                });
    }

    const findRole = async () => {
        // try {
        //     await runTransaction(getFirestore(app), async transaction => {
        //         const sfDoc = await transaction.get(sfDocRef);
        //         if (!sfDoc.exists()) throw "Document does not exist!";
        //         const newPopulation = sfDoc.data().population + 1;
        //         transaction.update(sfDocRef, { population: newPopulation });
        //     });

        //     alert("Transaction successfully committed!");
        // } catch (e) { alert(e); }
    }
    
    
	return !userId ? (
		<>
            <nav class="navbar navbar-expand-lg navbar-light bg-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand text-success fw-bold" href="#">ZETHEDWARD</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    {/* <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">About</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" href="#">Contact</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </nav>

            {/* HEADLINE */}
            <div id="carouselExampleCaptions" class="carousel slide bg-light" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        {/* <img src={bisligFront} class="d-block w-100" /> */}
                        <video controls src={bislig} type="video/mp4" className="w-100" />
                    </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            {/* HOSPITAL */}
            <div className="container-fluid bg-light p-4">
                <span className="text-light fw-bold bg-danger p-2 rounded-3">ST MARY WASHINGTON GENERAL HOSPITAL</span>
                <span className="fw-bold d-block display-4">Site Inspection</span>

                <br />

                <div className="row g-2">
                    <div className="col-lg-6">
                        <div className="h-100"><img src={inspect1} className="img-fluid" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div className="h-100"><img src={inspect2} className="img-fluid" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div className="h-100"><img src={inspect3} className="img-fluid" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div className="h-100"><img src={inspect4} className="img-fluid" /></div>
                    </div>

                    <div className="col-lg-12">
                        <div className="h100"><video muted autoPlay playsInline loop src={bisligShow} type="video/mp4" className="w-100" /></div>
                    </div>
                </div>
            </div>

            {/* DORMITEL */}
            <div className="container-fluid bg-light p-4">
                <span className="text-light fw-bold bg-danger p-2 rounded-3">DORMITEL</span>
                <span className="fw-bold d-block display-4">Model</span>

                <br />

                <div className="row g-2">

                    <div className="col-md-6">
                        <div className="h-100 p-4">
                            <img src={bisligFront} className="img-fluid w-100" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="h-100 p-4">
                            <img src={bisligRear} className="img-fluid w-100" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="h-100 p-4">
                            <img src={bisligLeft} className="img-fluid w-100" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="h-100 p-4">
                            <img src={bisligRight} className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>

            {/* ZEDC PROPOSED HEADQUARTERS */}
            <div className="container-fluid bg-light p-4">
                <span className="text-light fw-bold bg-danger p-2 rounded-3">ZETH EDWARD DEVELOPMENT CORPORATION</span>
                <span className="fw-bold d-block display-4">Proposed Headquarters</span>

                <br />

                <div className="row g-2">
                    <div className="col-lg-6">
                        <div><img src={office7} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office8} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office1} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office2} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office3} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office4} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office5} className="img-fluid w-100" /></div>
                    </div>

                    <div className="col-lg-6">
                        <div><img src={office6} className="img-fluid w-100" /></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light p-4">
                <div className="row g-4">
                    <div className="col-md-6">
                        <h2>OUR MISSION</h2>
                        <p className="fw-bold">To safely deliver a timely, cost-effective, quality, and innovative project that exceeds client’s expectations, achieving the highest levels of professionalism, integrity, honesty, and fairness in our relationships with our suppliers, subcontractors, professional associates, and customers.</p>
                    </div>
                    <div className="col-md-6">
                        <h2>OUR VISION</h2>
                        <p className="fw-bold">To become the contractor of choice trusted to safe, economical, and quality work. Also, to be the most dependable construction supplier firm for achieving excellent expertise while building and maintaining prominence of integrity.</p>
                    </div>

                    <div className="col-lg-12">
                        <h1 className="text-center">Core Values</h1>
                    </div>

                    <div className="col-lg-4">
                        <div className="h-100 rounded-3 p-4">
                            <h4>SAFETY & HEALTH</h4>
                            <p>We are committed to providing a safe and healthy working environment to protect our employees and the public every day</p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="h-100 rounded-3 p-4">
                            <h4>TEAMWORK</h4>
                            <p>The excellence of our work depends on our people with their ideas, energy, and collaboration. We work together towards one goal – to meet our client’s needs and exceed their expectations. This is essential for us not only for the company’s success but also for our team’s personal growth.</p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="h-100 rounded-3 p-4">
                            <h4>INTEGRITY</h4>
                            <p>This is earned through our performance as we take pride in our work and are accountable for our decisions, promises, and actions. We build lasting relationships through honesty, respect, and trust with our employees, clients as well as business partners.</p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="h-100 rounded-3 p-4">
                            <h4>PROFESSIONAL EXCELLENCE</h4>
                            <p>We strive to adhere to the highest standard of excellence in every project by doing our best according to the required processes, procedures, and customer requirements. We work as partners and trusted advisors with our clients and help them to save their money through cost-effective solutions and valueadded services while making a fair profit.</p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="h-100 rounded-3 p-4">
                            <h4>PASSION FOR INNOVATION</h4>
                            <p>Change is inevitable so we can make sure that our business is flexible enough to adapt to the innovation emersion of new construction techniques and sustainability in our environment. We do not doubt that this innovation would help us execute construction works in a better and more efficient way that will give our clients satisfaction and comfort living.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-success text-light text-center p-4">
                <h4 className="display-5 mb-4">ZETH EDWARD DEVELOPMENT CORPORATION (OPC.)</h4>
                <span className="d-block fw-bold"><i className="bi-geo-fill me-2"></i>0337 J.P. Rizal, Mahabang Parang, Santa Maria, Bulacan</span>
                <span className="d-block fw-bold"><i className="bi-telephone-fill me-2"></i>+63 927 151 8069</span>
                <span className="d-block fw-bold"><i className="bi-envelope-fill me-2"></i>build@zethedward.com</span>
            </div>
        </>):(<Navigate to="/projects" />);
};