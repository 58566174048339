import { getDocs, collection, getFirestore, doc, getDoc, updateDoc, addDoc, writeBatch, query, limit, orderBy } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import app from '../firebase';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import ContentEditable from 'react-contenteditable';

import zedc from '../zedc.png';

export default function RequestPrint() {

    const { request_id } = useParams();
	const [projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const [request, setRequest] = useState();
    const [project, setProject] = useState();


    const getRequest = () => {
        getDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`))
            .then(result => setRequest([result.id, result.data()]))
    }

    const getProject = () => {
        getDoc(doc(getFirestore(app), 'projects', projectId)).then(result => setProject([result.id, result.data()]));
    }

    useEffect(() => {
        getRequest();
        getProject();
    }, []);

    const updateEntity = (entity, value) => {
        if (entity == 'preparedBy')         updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`), { preparedBy: value });
        else if (entity == 'requestedBy')   updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`), { requestedBy: value });
        else if (entity == 'checkedBy')     updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`), { checkedBy: value });
        else if (entity == 'approvedBy')    updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`), { approvedBy: value });
    }

    const updatePurpose = value => {
        updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${request_id}`), { purpose: value });
    }

	return (
		<>
            <div className="container-fluid">
                <img src={zedc} className="img-fluid d-block mx-auto" style={{ width: 800 }} />
                {/* <h1 className="text-center text-success">ZETH EDWARD DEVELOPMENT CORPORATION (OPC.)</h1>
                <span className="d-block text-center">0337 J.P. Rizal St., Mahabang Parang, Sta. Maria, Bulacan</span>
                <span className="d-block text-center">(+63) 915 342 6297</span> */}
                
                <hr />

                <h6 className="text-center">PROJECT REQUISITION FORM</h6>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <small className="d-block">Project: { project && project[1].title }</small>
                            <small className="d-block">Location: { project && project[1].location }</small>
                        </div>
                        <div className="col-lg-6">
                            <small className="d-block">Date of Request: { request && moment(request[1].date.request.toDate()).format('LL') }</small>
                            <small className="d-block text-danger fw-bold">PRF# { request && request[1].number }</small>
                        </div>
                    </div>
                </div>
                

                <br />

                <table class="table table-sm table-borderless text-center border">
                    <thead>
                        <tr>
                        <th scope="col"><small>No.</small></th>
                        <th scope="col"><small>Description</small></th>
                        <th scope="col"><small>Unit of Measure</small></th>
                        <th scope="col"><small>Quantity</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            request && request[1].items.map((i, index) => (
                                <tr>
                                    <td className="fw-bold"><small>{index + 1}</small></td>
                                    <td><small>{i.description}</small></td>
                                    <td><small>{i.uom}</small></td>
                                    <td><small>{i.quantity}</small></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="container">
                    <h6>Purpose</h6>
                    <ContentEditable html={ request && request[1].purpose } onChange={data => updatePurpose(data.target.value)} tagName='p' style={{ fontSize: 13 }} />
                </div>
                
                <br />

                <div className="row px-2">
                    <div className="col-md-3 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Prepared by:</small>
                        <br />
                        <ContentEditable html={ request && request[1].preparedBy ? request[1].preparedBy:'' } onChange={data => updateEntity('preparedBy', data.target.value)} tagName='div' className="text-center fw-bold" style={{ fontSize: 12 }} />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>

                    <div className="col-md-3 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Requested by:</small>
                        <br />
                        <ContentEditable html={ request && request[1].requestedBy ? request[1].requestedBy:'' } onChange={data => updateEntity('requestedBy', data.target.value)} tagName='div' className="text-center fw-bold" style={{ fontSize: 12 }} />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>

                    <div className="col-md-3 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Checked/Verified by:</small>
                        <br />
                        <ContentEditable html={ request && request[1].checkedBy ? request[1].checkedBy:'' } onChange={data => updateEntity('checkedBy', data.target.value)} tagName='div' className="text-center fw-bold" style={{ fontSize: 12 }} />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>
                    <div className="col-md-3 border">
                        <small className="d-block" style={{ fontSize: 11 }}>Approved by:</small>
                        <br />
                        <ContentEditable html={ request && request[1].approvedBy ? request[1].approvedBy:'' } onChange={data => updateEntity('approvedBy', data.target.value)} tagName='div' className="text-center fw-bold" style={{ fontSize: 12 }} />
                        <small className="d-block text-center" style={{ fontSize: 10 }}>Signature over printed name</small>
                    </div>
                </div>

                <small classNam="d-block">Expected date of delivery: { request && moment(request[1].date.delivery.toDate()).format('LL') }</small>
            </div>
        </>
	);
};