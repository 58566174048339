import { getDocs, collection, getFirestore, doc, getDoc, setDoc, deleteDoc, updateDoc, addDoc, writeBatch, query, limit, orderBy, where } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import app from '../firebase';
// import ContentEditable from 'react-contenteditable';

import Header from '../components/header';
import Footer from '../components/footer';

import moment from 'moment';

export default function Orders() {

    const navigate = useNavigate();
    var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' });
    const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));
	const [projectId, setProjectId] = useState(localStorage.getItem('projectId'));
	const [userId, setUserId]       = useState(localStorage.getItem('userId'));

    const [orders, setOrders] = useState([]); // list of all orders
    const [requests, setRequests] = useState([]); // list of pending requests
    const [items, setItems] = useState([]);

    const [requestNumber, setRequestNumber] = useState();
    const [orderNumber, setOrderNumber] = useState();

    // new order
    const [order, setOrder] = useState(); // new order
    const [to, setTo] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [attention_1, setAttention_1] = useState('');
    const [attention_2, setAttention_2] = useState('');
    const [tin, setTIN] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [deliverTo, setDeliverTo] = useState('');

    const [deliveryDate, setDeliveryDate] = useState();
    const [wo_tax, setWoTax] = useState();
    const [tax, setTax] = useState();
    const [amount, setAmount] = useState(0);

    const getRequests = async () => {
        let x = [];
        await getDocs(query(collection(getFirestore(app), `projects/${projectId}/requests`), where('status', '==', 'pending'))).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setRequests(x);
    }

    const getRequest = async id => {
        let current_request;
        await getDoc(doc(getFirestore(app), `projects/${projectId}/requests/${id}`)).then(result => current_request = [result.id, result.data()]);
        localStorage.setItem('order', JSON.stringify({ request: { id: current_request[0], number: current_request[1].number, delivery: current_request[1].date.delivery.toDate() }, items: current_request[1].items }));
        setOrder({ request: { id: current_request[0], number: current_request[1].number, delivery: current_request[1].date.delivery.toDate() }, items: current_request[1].items });
    }

    const getOrders = async () => {
        let x = [];
        await getDocs(query(collection(getFirestore(app), `projects/${projectId}/orders`), orderBy('number'))).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setOrders(x);
    }

    const getRequestItems = async requestId => {
        let x = [];
        await getDoc(doc(getFirestore(app), `projects/${projectId}/requests/${requestId}`)).then(results => results.items.forEach(i => x.push(i)));
        setItems(x);
    }

    const updateRequestItem = (index, label, value) => {
        let current_request = JSON.parse(localStorage.getItem('order'));

        if (label == 'price') {
            if (isNaN(value)) value = 0;
            current_request.items[index].price = parseFloat(value);
        } else if (label == 'quantity') {
            if (isNaN(value)) value = 0;
            current_request.items[index].quantity = parseInt(value);
        } else current_request.items[index][label] = value;

        current_request.items[index].amount = current_request.items[index].quantity * current_request.items[index].price;
        localStorage.setItem('order', JSON.stringify(current_request));
        setOrder(current_request);
        updateWoTax();
    }

    const removeRequestItem = x => {
        let current_request = JSON.parse(localStorage.getItem('order'));
        current_request.items = current_request.items.filter(i => i.description !== x);
        localStorage.setItem('order', JSON.stringify(current_request));
        setOrder(current_request);
        updateWoTax();
    }

    const updateWoTax = () => {
        let current_request = JSON.parse(localStorage.getItem('order'));

        let x = 0;
        current_request.items.forEach(i => { if (!isNaN(i.amount)) x = x + i.amount });
        setWoTax(x);
    }

    const addData = async (id, section, label, value) => {
        
        if (section == 'monthy_salary_credit') {
            if (label == 'regular_social_security') await updateDoc(doc(getFirestore(app), `sss/${id}`), { monthly_salary_credit: { regular_social_security: value } });
            else if (label == 'mandatory_provident_fund') await updateDoc(doc(getFirestore(app), `sss/${id}`), { monthly_salary_credit: { mandatory_provident_fund: value } });
            else if (label == 'total') await updateDoc(doc(getFirestore(app), `sss/${id}`), { monthly_salary_credit: { total: value } });
        }

        else if (section == 'amount_of_contributions') {
            if (label == 'regular_social_security') await updateDoc(doc(getFirestore(app), `sss/${id}`), { amount_of_contributions: { regular_social_security: value } });
            else if (label == 'mandatory_provident_fund') await updateDoc(doc(getFirestore(app), `sss/${id}`), { amount_of_contributions: { mandatory_provident_fund: value } });
            else if (label == 'total') await updateDoc(doc(getFirestore(app), `sss/${id}`), { amount_of_contributions: { total: value } });
        }
    }

    const sendOrder = async () => {
        let new_order = {
            request: {
                id: order.request.id,
                number: order.request.number
            },
            to,
            address,
            phone,
            attentions: [attention_1, attention_2],
            items: order.items,
            tin,
            payment_terms: paymentTerms,
            deliver_to: deliverTo,
            total: {
                wo_tax: parseFloat(wo_tax),
                tax: parseFloat(tax),
                amount: parseFloat(wo_tax) + parseFloat(tax)
            },
            date: {
                order: new Date(),
                delivery: new Date(order.request.delivery) // expected date of delivery from request
            }
        };

        // add serial number to new_order
        await getDocs(collection(getFirestore(app), `projects/${projectId}/orders`)).then(results => new_order.number = results.size + 1);

        new_order.status = 'pending';
        // new_order.purpose = purpose;
        // new_order.date = { order: new Date(), delivery: new Date(delivery) };

        let created_order = await addDoc(collection(getFirestore(app), `projects/${projectId}/orders`), new_order);
        alert('Order has been successfully sent!');

        // update request status as approved
        updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${order.request.id}`), { status: 'approved' });
        navigate(`/order/${created_order.id}`);
    }

    const rejectRequest = () => {
        updateDoc(doc(getFirestore(app), `projects/${projectId}/requests/${order.request.id}`), { status: 'rejected' });
        alert('Request successfully rejected!');
    }


    // on-load
    useEffect(() => {
        getRequests();
        getOrders();
    }, []);

    // when tax value changed
    useEffect(() => {
        //
    }, [tax]);

	return (
		<>
            <Header />

            <div className="container-fluid bg-light min-vh-100 p-4">
                <div className="row">
                    <div className="col-lg-3">
                        <select className="form-select form-select-sm mb-2" onChange={data => getRequest(data.target.value)}>
                            <option value="" disabled selected>Select Request Number</option>
                            { requests.map(request => <option value={request[0]}>{request[1].number}</option>) }
                        </select>

                        <input type="text" className="form-control form-control-sm mb-2" placeholder="To" onChange={data => setTo(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Address" onChange={data => setAddress(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Telephone" onChange={data => setPhone(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Attention 1" onChange={data => setAttention_1(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Attention 2" onChange={data => setAttention_2(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="TIN" onChange={data => setTIN(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Terms of Payment" onChange={data => setPaymentTerms(data.target.value)} />
                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Delivery To" onChange={data => setDeliverTo(data.target.value)} />
                        
                        <br />
                        
                        <span className="d-block fw-bold">Amount w/o Tax: {wo_tax}</span>
                        <input type="number" className="form-control form-control-sm" placeholder="Tax" onChange={data => setTax(data.target.value)} />
                        <span className="d-block fw-bold">Total Amount: {parseFloat(wo_tax) + parseFloat(tax)}</span>

                        <br />

                        <button className="btn btn-sm btn-primary w-100 mb-2" onClick={sendOrder}>Save Order</button>
                        <button className="btn btn-sm btn-outline-danger w-100" onClick={rejectRequest}>Reject</button>
                    </div>

                    <div className="col-lg-9">
                        <table class="table table-sm table-borderless text-center">
                            <thead>
                                <tr>
                                <th scope="col">Description</th>
                                <th scope="col">Unit of measure</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>
                                <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order && order.items.map((i, index) => (
                                        <tr>
                                            <td><input type="text" className="form-control form-control-sm" defaultValue={i.description} onChange={data => updateRequestItem(index, 'description', data.target.value)} /></td>
                                            <td><input type="text" className="form-control form-control-sm" defaultValue={i.uom} onChange={data => updateRequestItem(index, 'uom', data.target.value)} /></td>
                                            <td><input type="number" className="form-control form-control-sm" defaultValue={i.quantity} onChange={data => updateRequestItem(index, 'quantity', data.target.value)} /></td>
                                            <td><input type="number" className="form-control form-control-sm" onChange={data => updateRequestItem(index, 'price', data.target.value)} /></td>
                                            <td>{formatter.format(i.amount)}</td>
                                            <td><button className="btn btn-sm btn-danger" onClick={() => removeRequestItem(i.description)}><i className="bi-x"></i></button></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr />

                <h4>Purchase Order List</h4>
                <br />

                <table class="table table-sm table-borderless text-center">
                    <thead>
                        <tr>
                            <th scope="col">PO #</th>
                            <th scope="col">Date Created</th>
                            {/* <th scope="col">Expected Delivery</th> */}
                            <th scope="col">Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {orders.map(i => (
                            <tr>
                                <th role="button" scope="row" onClick={() => navigate(`/order/${i[0]}`)}>{i[1].number}</th>
                                <td role="button" onClick={() => navigate(`/order/${i[0]}`)}>{moment(i[1].date.order.toDate()).format('l')}</td>
                                <td role="button" onClick={() => navigate(`/order/${i[0]}`)}>{i[1]['status'] == 'pending' ? <span className="text-warning fw-bold">pending</span>:<span>{i[1]['status']}</span>}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Footer />
        </>
	);
};