import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'material-icons/iconfont/material-icons.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import {useEffect, useState} from 'react';

import Index 			from './views/index';
import Projects 		from './views/projects';
import Register 		from './views/register';
import Login 			from './views/login';
import Logout 			from './views/logout';


// views
import Dashboard 	from './views/dashboard';
import Task 		from './views/task';
import Request 		from './views/request';
import RequestPrint from './views/request-print.js';
import Order 		from './views/order';
import OrderPrint 	from './views/order-print.js';
// import Supplier 	from './views/supplier';
import Inventory 	from './views/inventory';
import Accounting 	from './views/accounting';
// import Analytics 	from './views/analytics';
import Settings 	from './views/settings';

import CashReceiptsJournal from './views/cash-receipts-journal'

export default function App() {
	return (
		<Router>
			<Routes>
				<Route exact path="/"  	 	element={<Index />} />
				<Route path="/projects"  	element={<Projects />} />
				<Route path="/register"  	element={<Register />} />
				<Route path="/login" 	 	element={<Login />} />
				<Route path="/logout" 	 	element={<Logout />} />

				{/* MODULES */}
				<Route path="/dashboard"  	element={<Dashboard />} />
				<Route path="/task"  		element={<Task />} />
				<Route path="/request"  	element={<Request />} />
				<Route path="/request/:request_id" element={<RequestPrint />} />
				<Route path="/order"  		element={<Order />} />
				<Route path="/order/:order_id" element={<OrderPrint />} />
				{/* <Route path="/supplier"  	element={Supplier} /> */}
				<Route path="/inventory"  	element={<Inventory />} />
				<Route path="/accounting"  	element={<Accounting />} />

				<Route path="/accounting" element={<Accounting />} />
				<Route path="/accounting/cash-receipts-journal" element={<CashReceiptsJournal />} />
				<Route path="/accounting/purchase-journal" element={<Accounting />} />
				<Route path="/accounting/general-journal" element={<Accounting />} />

				{/* <Route path="/analytics"  	element={Analytics} /> */}
				<Route path="/settings"  	element={<Settings />} />
				{/* This contains major number of components */}

				{/* 404 */}
				<Route path="*"			 element={<Projects />} />
			</Routes>
		</Router>
	);
};