import { initializeApp } from 'firebase/app';

const app = initializeApp({
	apiKey: 			"AIzaSyDiDIX-PD3UX7leVTnreAGUkGFpvWEzbjo",
	authDomain: 		"zedc-2021.firebaseapp.com",
	projectId: 			"zedc-2021",
	storageBucket: 		"zedc-2021.appspot.com",
	messagingSenderId: 	"976400340520",
	appId: 				"1:976400340520:web:fce46f7f7179a90b18071d",
	measurementId: 		"G-Z3XL3L4C6F"
});

export default app;