import { useState, useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { getFirestore, doc, collection, getDocs, setDoc, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import app from '../firebase';
import nodemailer from 'nodemailer';

import Header from '../components/header';
import Footer from '../components/footer';

export default function Settings() {

    const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));
	const [projectId, setProjectId] = useState(localStorage.getItem('projectId'));
	const [userId, setUserId] = useState(localStorage.getItem('userId'));
	
    const [users, setUsers] = useState([]);

    // invite new user
    const [newUser, setNewUser] = useState({ email: '', password: '', permissions: [] });

    const getUsers = async () => {
        let x = [];
        await getDocs(collection(getFirestore(app), `users`)).then(results => results.forEach(result => x.push([result.id, result.data()])));
        setUsers(x);
    }

    const handleEmail = email => {
        let new_user = newUser;
        new_user.email = email;
        setNewUser(new_user);
    }

    const handlePassword = password => {
        let new_user = newUser;
        new_user.password = password;
        setNewUser(new_user);
    }

    const handlePermission = (permission, status) => {
        if (status) addPermission(permission);
        else removePermission(permission);
    }

    const addPermission = permission => {
        let new_user = newUser;
        new_user.permissions.push(permission);
        setNewUser(new_user);
    }

    const removePermission = permission => {
        let new_user = newUser;
        new_user.permissions = new_user.permissions.filter(i => i !== permission);
        setNewUser(new_user);
    }

    const register = async () => {
        let created_user = await createUserWithEmailAndPassword(getAuth(app), newUser.email, newUser.password);
        setDoc(doc(getFirestore(app), `users/${created_user.user.uid}`), { email: newUser.email, permissions: newUser.permissions });
        alert('User has been successfully registered!');
    }

    return (
        <>
            <Header />

            <div className="container-fluid bg-light p-4">
                <div className="row g-4">
                    <div className="col-lg-4">
                        <div className="h-100 bg-primary bg-gradient text-light rounded-3 p-4">
                            <h4>Invite User</h4>
                            <input type="email" className="form-control mb-2" placeholder="Email Address" onChange={data => handleEmail(data.target.value)} />
                            <input type="password" className="form-control mb-2" placeholder="Password" onChange={data => handlePassword(data.target.value)} />
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Dashboard" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Dashboard</label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Task" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Task</label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Request" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Request</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Order" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Order</label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Inventory" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Inventory</label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Accounting" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Accounting</label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Analytics" onClick={data => handlePermission(data.target.value, data.target.checked)} />
                                <label class="form-check-label">Analytics</label>
                            </div>

                            <br />

                            <button className="btn btn-light w-100" onClick={register}><i className="bi-envelope me-2"></i>Create User</button>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="h-100 rounded-3 shadow p-4">
                            <h4>Users</h4>
                            <table class="table table-sm table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Date Invited</th>
                                        <th scope="col">Date Joined</th>
                                        <th scope="col">Permissions</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Alden</td>
                                        <td>Vallestero</td>
                                        <td>March 10, 2021</td>
                                        <td>March 15, 2021</td>
                                        <td>
                                            <span className="bg-primary text-light mx-1 px-1 rounded-3">Dashboard<i className="bi-x ms-1" role="button"></i></span>
                                        </td>
                                        <td><button className="btn btn-sm btn-danger">Remove</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}