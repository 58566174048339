import { getDocs, collection, getFirestore, doc, getDoc, updateDoc, addDoc, writeBatch, query, limit, orderBy } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import app from '../firebase';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';

export default function Request() {

    const navigate = useNavigate();
    const [companyId, setCompanyId]     = useState(localStorage.getItem('companyId'));
	const [projectId, setProjectId]     = useState(localStorage.getItem('projectId'));
	const [userId, setUserId]           = useState(localStorage.getItem('userId'));
    const [requestId, setRequestId]     = useState();

    const [requests, setRequests]       = useState([]);

    // INITIAL
    const [initialItems, setInitialItems] = useState([]);

    // EXISTING
    const [requestItems, setRequestItems] = useState([]);


    const [requestNumber, setRequestNumber] = useState(0);
    const [whatTheType, setWhatTheType]     = useState('text');

	// Filters
	const [_sortBy, setSortBy]      = useState('requestId');
	const [_orderBy, setOrderBy]    = useState('desc');
	const [_status, setStatus]      = useState('all');

    // new structure of json requesting
    const [newRequest, setNewRequest] = useState();

    // each item
    const [description, setDescription] = useState();
    const [uom, setUOM] = useState();
    const [quantity, setQuantity]       = useState();

    // supplements
    const [purpose, setPurpose]         = useState();
    const [delivery, setDelivery]       = useState();
    
    const getRequestNumber  = async () => await getDocs(collection(getFirestore(app), `projects/${projectId}/requests`)).then(results => setRequestNumber(results.size + 1));


    const removeNewRequestItem = () => {
        let cart = localStorage.getItem('cart');
        
    }

	const getRequests = async () => {
		let x = [];
		await getDocs(query(collection(getFirestore(app), `projects/${projectId}/requests`), orderBy('number'))).then(results => results.forEach(result => x.push([result.id, result.data()])));
		setRequests(x);
	}

	// filter
	useEffect(async () => {
		let x = [];
		await getDocs(query(collection(getFirestore(app), `projects/${projectId}/requests`), orderBy(_sortBy, _orderBy)))
		.then(results => {
			if (_status != 'all') {
				results.forEach(result => {
					if (result.data().status == _status) x.push([result.id, result.data()]);
				});
			} else results.forEach(result => x.push([result.id, result.data()]));
			
		});
		
		setRequests(x);
	}, [_sortBy, _orderBy, _status]);
	
    const editRequest = async id => {
        setRequestId(id);
        await getRequest(id);
        await getItems(id);
    }

    // GET REQUEST TO EDIT
    const getRequest = async id => {
        await getDoc(doc(getFirestore(app), `projects/${projectId}/requests/${id}`))
            .then(result => {
                setRequestId(result.id);
                setRequestNumber(result.data().requestNumber);
                setQuantity(result.data().quantity)
                setPurpose(result.data().purpose);
                setDelivery(result.data().delivery);
                setUOM(result.data().uom)
            });
    }

    // GET REQUEST ITEMS TO EDIT
    const getItems = async id => {
        await getDocs(collection(getFirestore(app), `projects/${projectId}/requests/${id}/items`))
            .then(results => {
                let x = [];
                results.forEach(result => x.push([result.id, result.data()]));
                setRequestItems(x);
            });
    };



    // CREATING A NEW REQUEST

    const addItemToNewRequest = () => {
        if (!description || !uom || !quantity || isNaN(parseInt(quantity))) return alert('Please fill out all necessary information!');
        if (localStorage.getItem('request') === null) createNewRequest();
        else addItemToNewRequestItems();
        getNewRequest();
    }

    const createNewRequest = () => {
        localStorage.setItem('request', JSON.stringify({ items: [{ description, uom, quantity }] }));
    }

    const addItemToNewRequestItems = () => {
        let new_request = JSON.parse(localStorage.getItem('request'));
        new_request.items.push({ description, uom, quantity });
        localStorage.setItem('request', JSON.stringify(new_request));
    }

    const removeItemFromNewRequestItems = description => {
        let new_request = JSON.parse(localStorage.getItem('request'));
        new_request.items = new_request.items.filter(i => i.description !== description);
        setNewRequest(new_request);
        localStorage.setItem('request', JSON.stringify(new_request));
    }

    const getNewRequest = () => {
        setNewRequest(JSON.parse(localStorage.getItem('request')));
    }


    // SENDING A NEW REQUEST

    const sendNewRequest = async () => {
        if (!purpose || purpose == '' || purpose == ' ' || !delivery) return alert('Please fill out all necessary information!');

        let new_request = JSON.parse(localStorage.getItem('request'));

        // insert supplements
        await getDocs(collection(getFirestore(app), `projects/${projectId}/requests`))
            .then(results => new_request.number = results.size + 1);

        new_request.status = 'pending';
        new_request.purpose = purpose;
        new_request.date = { request: new Date(), delivery: new Date(delivery) };

        let created_request = await addDoc(collection(getFirestore(app), `projects/${projectId}/requests`), new_request);
        alert('New request has been successfully created!');
        navigate(`/request/${created_request.id}`);
        localStorage.removeItem('request');
    }   

    // ON PAGE LOADS
    
    useEffect(() => {
        getRequestNumber();
        getRequests();
    }, []);

	return (
		<>
            <Header />

            <div className="container-fluid bg-light min-vh-100 p-4">
                {/* LIST OF REQUESTS */}
                <div className="d-flex align-items-center justify-content-between">
                    <h4>Request List</h4>
                    <div>
                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#createRequest"><i className="bi-plus me-1"></i>Create Request</button>
                    </div>
                </div>
                <div></div>
                {/* <br />
                <div class="d-flex">
                    <div class="mx-2">
                        <label class="form-label">Sort by</label>
                        <select class="form-select" onChange={data => setSortBy(data.target.value)}>
                            <option value="requestId" selected>PRF #</option>
                            <option value="date">Date Created</option>
                            <option value="delivery">Expected Delivery</option>
                        </select>
                    </div>
                    <div class="mx-2">
                        <label class="form-label">Order by</label>
                        <select class="form-select" onChange={data => setOrderBy(data.target.value)}>
                            <option value="asc" selected>Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </div>

                    <div class="mx-2">
                        <label class="form-label">Status</label>
                        <select class="form-select" onChange={data => setStatus(data.target.value)}>
                            <option value="all" selected>All</option>
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>
                        </select>
                    </div>
                </div> */}

                <br />

                <table class="table table-sm text-center">
                    <thead>
                        <tr>
                            <th scope="col">PRF #</th>
                            <th scope="col">Date Created</th>
                            <th scope="col">Expected Delivery</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {requests.map(request => (
                            <tr>
                                <th scope="row" role="button" onClick={() => navigate(`/request/${request[0]}`)}>{request[1].number}</th>
                                <td role="button" onClick={() => navigate(`/request/${request[0]}`)}>{moment(request[1].date.request.toDate()).format('l')}</td>
                                <td role="button" onClick={() => navigate(`/request/${request[0]}`)}>{moment(request[1].date.delivery.toDate()).format('l')}</td>
                                <td role="button" onClick={() => navigate(`/request/${request[0]}`)}>{request[1]['status'] == 'pending' ? <span className="text-warning fw-bold">pending</span>:<span className="fw-bold">{request[1]['status']}</span>}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Footer />

            {/* CREATE REQUEST */}
            <div class="modal fade" id="createRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Request #{requestNumber}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <input type="text" className="form-control mb-2" placeholder="Item Description" onChange={data => setDescription(data.target.value)} />
                                    <input type="text" className="form-control mb-2" placeholder="Unit of Measure" onChange={data => setUOM(data.target.value)} />
                                    <input type="number" className="form-control mb-2" placeholder="Quantity" onChange={data => setQuantity(data.target.value)} />
                                    <button className="btn btn-primary w-100 mb-2" onClick={addItemToNewRequest}>Add Item</button>
                                </div>

                                <div className="col-lg-9">
                                    <table class="table table-sm table-borderless text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">UOM</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                newRequest && newRequest.items.map(i => (
                                                    <tr>
                                                        <td>{i.description}</td>
                                                        <td>{i.uom}</td>
                                                        <td>{i.quantity}</td>
                                                        <td><button className="bi bi-x btn btn-sm btn-outline-danger rounded-circle" onClick={() => removeItemFromNewRequestItems(i.description)}></button></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <textarea className="form-control mb-2" placeholder="Purpose" rows="4" onChange={data => setPurpose(data.target.value)}></textarea>
                            <input type={whatTheType} className="form-control mb-2" placeholder="Expected Delivery Date" onFocus={() => setWhatTheType('date')} onBlur={() => setWhatTheType('text')} onChange={data => setDelivery(data.target.value)} />
                            <button className="btn btn-primary w-100 mb-2" onClick={sendNewRequest} data-bs-dismiss="modal" aria-label="Close">Send Request</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Modal */}
            <div class="modal fade" id="editRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">PRF# {requestNumber}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">

                                <div className="col-12 col-md-12">
                                    <table class="table table-sm text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">UOM</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {/* {
                                                requestItems.map(i => (
                                                    <tr>
                                                        <td>{i[1].item}</td>
                                                        <td>{i[1].description}</td>
                                                        <td>{i[1].uom}</td>
                                                        <td><input type="text" className="form-control" defaultValue={i[1].quantity} onChange={data => updateQuantity(i[0], data.target.value)} /></td>
                                                        <td><button className="bi bi-x btn btn-sm btn-danger rounded-circle"></button></td>
                                                    </tr>
                                                ))
                                            } */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
	);
};