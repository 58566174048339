/*
    NOTE: THIS IS THE FIRST COMPONENT THAT WILL BE DISPLAYED AFTER /DASHBOARD GET REQUEST
*/
import { useState, useEffect } from 'react';
import { Line } 		        from 'react-chartjs-2';
import app                      from '../firebase';
import { getFirestore, doc, collection, updateDoc, getDoc, getDocs } from 'firebase/firestore';

import Header from '../components/header';
import Footer from '../components/footer';
import moment from 'moment';
import { useNavigate } from 'react-router';

export default function Dashboard() {

    const navigate = useNavigate();
    const [companyId, setCompanyId]                 = useState(localStorage.getItem('companyId'));
	const [projectId, setProjectId]                 = useState(localStorage.getItem('projectId'));
	const [userId, setUserId] 		                = useState(localStorage.getItem('userId'));

    const [project, setProject] = useState();

    // project details
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [description, setDescription] = useState();
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [budget, setBudget] = useState();

	const data = {
		labels: ['1', '2', '3', '4', '5', '6'],
		datasets: [
			{
				label: 'Monthly Expenses',
				data: [12, 19, 3, 5, 2, 3],
				fill: false,
				backgroundColor: '#112D4E',
				borderColor: '#112D4E',
			},
		],
	};

	const options = { scales: { yAxes: [ { ticks: { beginAtZero: true, }, }, ], }, };

    const getProject = () => {
        getDoc(doc(getFirestore(app), 'projects', projectId))
            .then(result => setProject([result.id, result.data()]));
    }
    
    const [percentageDone, setPercentageDone] = useState();
    const getPercentageDone = async () => {
        let total = 0;
        let done = 0;
        await getDocs(collection(getFirestore(app), `projects/${projectId}/tasks`))
            .then(results => {
                total = results.size;
                results.forEach(result => { if (result.data().done) done = done + 1 });
            }); setPercentageDone(parseInt((done/total) * 100));
    }

    const updateProject = (label, value) => {
        if      (label == 'title')          updateDoc(doc(getFirestore(app), 'projects', projectId), { title: value });
        else if (label == 'location')       updateDoc(doc(getFirestore(app), 'projects', projectId), { location: value });
        else if (label == 'description')    updateDoc(doc(getFirestore(app), 'projects', projectId), { description: value });
        else if (label == 'start')          updateDoc(doc(getFirestore(app), 'projects', projectId), { start: new Date(value) });
        else if (label == 'end')            updateDoc(doc(getFirestore(app), 'projects', projectId), { end: new Date(value) });
        else if (label == 'budget')         updateDoc(doc(getFirestore(app), 'projects', projectId), { budget: value });
    }

    useEffect(() => {
        getProject();
        getPercentageDone();
    }, []);

    return (
        <>
            <Header />

            <div className=""></div>

            <div className=""></div>

            <div className="container-fluid bg-light min-vh-100 p-4">
                <div className="row g-2">

                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="h-100 p-4">
                            <div className="mb-2">
                                <label className="form-label">Project Title</label>
                                <input type="text" className="form-control" defaultValue={ project && project[1].title } onChange={data => updateProject('title', data.target.value)} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label">Project Location</label>
                                <input type="text" className="form-control" defaultValue={ project && project[1].location } onChange={data => updateProject('location', data.target.value)} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label">Project Description</label>
                                <textarea rows="4" className="form-control" defaultValue={ project && project[1].description } style={{ resize: 'none' }} onChange={data => updateProject('description', data.target.value)}></textarea>
                            </div>

                            <div className="mb-2">
                                <label className="form-label">Project Start</label>
                                <input type="date" className="form-control" defaultValue={ project && project[1].start.toDate() } onChange={data => updateProject('start', data.target.value)} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label">Project End</label>
                                <input type="date" className="form-control" defaultValue={ project && project[1].end.toDate() } onChange={data => updateProject('end', data.target.value)} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label">Project Budget</label>
                                <input type="number" className="form-control" defaultValue={ project && project[1].budget } onChange={data => updateProject('budget', data.target.value)} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12 col-md-6 col-lg-6">
                        <div className="h-100 bg-primary bg-gradient text-light rounded-3 p-4">
                            <span className="d-block">The project is</span>
                            <h1 style={{ color: `rgb(255, ${percentageDone + 155}, ${percentageDone + 155})` }}>{percentageDone}%</h1>
                            <span className="d-block">Done.</span>

                            <button className="btn btn-sm btn-light mt-2" onClick={() => navigate('/task')}>Check remaining tasks</button>
                        </div>
                    </div> */}

                    {/* <div className="col-12 col-md-6 col-lg-6">
                        <div className="h-100 p-4">
                            <Line data={data} options={options} />
                        </div>
                    </div> */}

                    {/* <div className="col-12 col-md-4">
                        <div className="h-100 bg-success text-light bg-gradient rounded-3 p-4">
                            <h4>Payroll (BETA)</h4>
                            <input type="text" className="form-control mb-2" placeholder="Employee ID" />
                            <input type="number" className="form-control mb-2" placeholder="Rate Amount (PHP)" />
                            <input type="number" className="form-control mb-2" placeholder="Number of hours" />
                            <button className="btn btn-light w-100"><i className="bi-cash me-2"></i>Send Money</button>
                        </div>
                    </div> */}
                </div>
            </div>

            <Footer />
        </>
    )
}