import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import app from '../firebase';

export default function Login() {
    
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();

    const login = async () => {
        await signInWithEmailAndPassword(getAuth(app), email, password)
            .then(async result => {
                localStorage.setItem('userId', result.user.uid);
                await getUserProfile(result.user.uid);
                navigate('/projects');
            }).catch(e => alert(e));
    };

    const getUserProfile = async userId => {
            await getDoc(doc(getFirestore(app), `users/${userId}`))
                .then(result => {
                    localStorage.setItem('userRole', result.data().role);
                    localStorage.setItem('companyId', result.data().company);
                });
    }

    const findRole = async () => {
        // try {
        //     await runTransaction(getFirestore(app), async transaction => {
        //         const sfDoc = await transaction.get(sfDocRef);
        //         if (!sfDoc.exists()) throw "Document does not exist!";
        //         const newPopulation = sfDoc.data().population + 1;
        //         transaction.update(sfDocRef, { population: newPopulation });
        //     });

        //     alert("Transaction successfully committed!");
        // } catch (e) { alert(e); }
    }
    
	return !userId ? (
		<div className="container-fluid bg-primary d-flex min-vh-100 align-items-center justify-content-center p-4">
            <div className="p-4 bg-light shadow rounded-3">
                <h1 className="text-dark"><i className="bi bi-shield-lock text-primary me-2"></i>Login</h1>

                <br />

                <input type="email" className="form-control mb-2" placeholder="Email Address" onChange={data => setEmail(data.target.value)} />
                <input type="password" className="form-control mb-2" placeholder="Password" onChange={data => setPassword(data.target.value)} />
                
                <button className="btn btn-primary mb-2 w-100" onClick={login}>Login</button>
            </div>
		</div>
    ):(<Navigate to="/projects" />);
};